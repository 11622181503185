import React from "react"
import { useGetComments } from "../../../hook/useGetComments"
import Comments from "../Comments/Comments"
import Comment from "../Comments/Comment"
import styledComment from "./comments.mod.scss"
import Block from "@global/layout/Block"

const List = ({ id }) => {
  const { loading, comments } = useGetComments(id)

  if (loading) return <p>loading...</p>

  return (
    <Block padding="top" gutters={true} maxWidth="inner">
      <Comments comments={comments} loading={loading}>
        {comments.map(comment => {
          return (
            <li className={styledComment.comments__item} key={comment.id}>
              <Comment comment={comment} />
            </li>
          )
        })}
      </Comments>
    </Block>
  )
}
export default List
