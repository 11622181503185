import React from "react"
import { useAuth0 } from "@utils/auth"
import Block from "@global/layout/Block"
import style from "./comments.mod.scss"

const SubmitHeader = ({ text, loggedAs = "" }) => {
  const { logout, user } = useAuth0()

  return (
    <Block gutters={true} maxWidth="inner">
      <div className={style.comments}>
        <h3>{text}</h3>
        <p>
          {`Logged in as ${loggedAs}.`}
          <a
            href
            className={style.comments__logout}
            onClick={() =>
              logout({ returnTo: process.env.GATSBY_AUTH0_CALLBACK })
            }
          >{`Logout?`}</a>
        </p>
      </div>
    </Block>
  )
}

export default SubmitHeader
