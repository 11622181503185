import React from "react"
import { graphql } from "gatsby"
import Layout from "@layouts/layout"
import EatGreat from "../components/eatGreat/eatGreat"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { ProtectedRoute } from "../components/ProtectedRoute"

const EatGreatDetail = ({
  data: { eatGreat, seo },
  pageContext: { parent, title, language }
}) => {
  return (
    <ProtectedRoute>
      <Layout parent={parent} title={title}>
        <HelmetDatoCms
          defer={false}
          seo={eatGreat.seoMetaTags}
          language={language}
        />
        <EatGreat {...eatGreat} />
      </Layout>
    </ProtectedRoute>
  )
}
export const query = graphql`
  query eatGreatById($id: String) {
    eatGreat: datoCmsEatGreat(id: { eq: $id }) {
      ...EatGreat
    }
    seo: datoCmsSite {
      ...GlobalSeo
    }
  }
`

export default EatGreatDetail
