import React, { useState } from "react"
import { graphql } from "gatsby"
import style from "./vimeo.mod.scss"
import { GatsbyImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"
import Button from "@elements/Button"
import { CastButton, useCast, useCastPlayer } from "react-cast-sender"
import PlayButton from "@svgs/playButton.svg"
import { motion } from "framer-motion"

const Vimeo = ({
  videoUrl,
  audioFile,
  timestamp,
  poster,
  fileUrl,
  aspectRatio = 16 / 9
}) => {
  const isBrowser = typeof window !== "undefined"
  const { initialized, connected, deviceName } = useCast()
  const [video, setVideo] = useState({
    loading: true,
    startAt: 0
  })

  const [audio, setAudio] = useState(false)

  const VimeoPlayer = () => {
    const src = `https://player.vimeo.com/video/${videoUrl.providerUid}#t=${video.startAt}`
    return (
      <div className={style.vimeo__player}>
        <iframe
          src={src}
          width="640"
          height="360"
          title={videoUrl.title}
          frameBorder="0"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          allowFullScreen
          className={style.vimeo__iframe}
        />
      </div>
    )
  }

  const VimeoAudio = () => (
    <>
      <div className={style.vimeo__audioWrap}>
        <div className={style.vimeo__playerWrap}>
          <audio className={style.vimeo__audio} controls src={audioFile}>
            <track kind="captions" />
          </audio>
        </div>
      </div>
    </>
  )

  const VimeoCast = () => {
    const chrome = isBrowser && window.chrome
    const { loadMedia } = useCastPlayer()

    if (initialized && chrome !== undefined && connected) {
      var mediaInfo = new chrome.cast.media.MediaInfo(fileUrl, "video/mp4")
      const metadata = new chrome.cast.media.MovieMediaMetadata()
      metadata.title = videoUrl.title
      metadata.images = [new chrome.cast.Image(poster.src)]
      mediaInfo.metadata = metadata

      var request = new chrome.cast.media.LoadRequest(mediaInfo)

      loadMedia(request)
    }

    return (
      <>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className={style.vimeo__overlay}
        />

        {connected && (
          <div className={style.vimeo__dialog}>
            {connected && <h4>{`Casting to ${deviceName}`}</h4>}
          </div>
        )}
      </>
    )
  }

  const VimeoPlaceholder = () => {
    return (
      <div key={"placeholder"} className={style.vimeo__placeholder}>
        {poster && (
          <GatsbyImage
            className={style.vimeo__image}
            // fluid={{ ...poster, aspectRatio: aspectRatio }}
            image={poster.gatsbyImageData}
          />
        )}
        {fileUrl && connected && <VimeoCast />}
        {!connected && (
          <motion.button
            initial={{ opacity: 0, scale: 0.75 }}
            animate={{ opacity: 1, scale: 1 }}
            className={style.vimeo__play}
            onClick={() => setVideo({ loading: false })}
          >
            <PlayButton />
          </motion.button>
        )}
        {!connected && timestamp && (
          <div className={style.vimeo__skip}>
            <Button
              type="button"
              size="small"
              text="Skip Intro"
              onClick={() => setVideo({ loading: false, startAt: timestamp })}
              arrow={true}
            />
          </div>
        )}
        {audioFile && !audio && (
          <div className={style.vimeo__listen}>
            <Button
              type="button"
              size="small"
              text="Listen"
              onClick={e => setAudio(true)}
            ></Button>
          </div>
        )}

        {audio && <VimeoAudio />}
      </div>
    )
  }

  const VideoMedia = () => {
    if (poster && video.loading) {
      return <VimeoPlaceholder />
    }

    return <VimeoPlayer />
  }

  const classes = [style.vimeo]

  connected && classes.push(style.vimeo__casting)

  return (
    <div className={classes.join(" ")}>
      {fileUrl && (
        <div className={style.vimeo__cast}>
          <CastButton />
        </div>
      )}
      <VideoMedia />
    </div>
  )
}

export default Vimeo

Vimeo.propTypes = {
  videoUrl: PropTypes.object.isRequired,
  timestamp: PropTypes.string,
  fileUrl: PropTypes.string,
  poster: PropTypes.object,
  aspectRatio: PropTypes.number
}

export const query = graphql`
  fragment Vimeo on DatoCmsVideo {
    model {
      apiKey
    }
    id
    videoUrl {
      url
      title
      provider
      providerUid
      thumbnailUrl
      width
      height
    }
  }

  fragment WorkoutVimeo on DatoCmsWorkoutVideo {
    id
    timestamp
    fileUrl
    url {
      height
      provider
      providerUid
      thumbnailUrl
      title
      url
      width
    }
    chosenThumbnail {
      gatsbyImageData(imgixParams: { w: "1280", h: "720", fit: "crop" })
    }
    model {
      apiKey
    }
  }
`
