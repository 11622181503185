import React from "react"
import { graphql } from "gatsby"
import style from "./eatGreat.mod.scss"
import { formatDate } from "@helpers/dateHelper"
import PropTypes from "prop-types"
import * as FirebaseComments from "../Comments"
import { useAuth0 } from "@utils/auth"
import { ProtectedRoute } from "../ProtectedRoute"
import Vimeo from "@elements/Vimeo"
import Block from "../global/layout/Block"
import Content from "@elements/Content"

const EatGreatDetailPage = ({
  id,
  originalId,
  title,
  description,
  descriptionNode,
  meta,
  url,
  language,
  audioFile,
  showComments = false,
  chosenThumbnail
}) => {
  const { loading, user } = useAuth0()

  return (
    <ProtectedRoute>
      <div className={style.eat}>
        <div className={style.eat__hero}>
          <Vimeo
            videoUrl={url}
            audioFile={audioFile && audioFile.url}
            poster={chosenThumbnail}
          />
        </div>
        <Block padding="top" gutters={true} maxWidth="inner">
          <div className={style.eat__content}>
            <div className={style.eat__top_row}>
              <p className={style.eat__category}>DediKate Eat Great</p>
            </div>
            <div className={style.eat__bottom_row}>
              <h1 className={style.eat__heading}>{title}</h1>
            </div>
            {meta && (
              <p className={style.eat__date}>{formatDate(meta.publishedAt)}</p>
            )}
            {description && (
              <Content html={descriptionNode.childMarkdownRemark.html} />
            )}
          </div>
        </Block>
        {showComments && (
          <EatGreatComments>
            <FirebaseComments.List id={originalId} />
            <FirebaseComments.SubmitHeader
              text="Submit a Comment"
              loggedAs={user?.name}
            />
            <FirebaseComments.SubmitForm id={id} originalId={originalId} />
          </EatGreatComments>
        )}
      </div>
    </ProtectedRoute>
  )
}

export default EatGreatDetailPage

export const EatGreatComments = ({ children }) => {
  return <>{children}</>
}

EatGreatDetailPage.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  meta: PropTypes.shape({
    publishedAt: PropTypes.string
  }).isRequired,
  url: PropTypes.shape({
    url: PropTypes.string,
    providerUid: PropTypes.string
  }).isRequired,
  showComments: PropTypes.bool
}

export const query = graphql`
  fragment EatGreat on DatoCmsEatGreat {
    id
    originalId
    slug
    title
    description
    descriptionNode {
      childMarkdownRemark {
        html
      }
    }
    audioFile {
      url
    }
    showComments
    seoMetaTags {
      ...GatsbyDatoCmsSeoMetaTags
    }
    url {
      url
      providerUid
    }
    chosenThumbnail {
      gatsbyImageData(imgixParams: { w: "1280", h: "720", fit: "crop" })
    }
    meta {
      publishedAt
    }
  }
`
