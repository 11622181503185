import React from "react"
import { formatDateTime } from "@helpers/dateHelper"
import { useAuth0 } from "@utils/auth"

const permissions = `${process.env.GATSBY_AUTH0_NAMESPACE}permissions`

const Comment = ({ comment }) => {
  const { user } = useAuth0()
  if (!comment) return null

  return (
    <div>
      <div>
        <strong>{comment?.name ? `${comment.name}` : "User"}</strong>
        {` on ${formatDateTime(comment.updatedAt.seconds * 1000)}`}
      </div>
      <p>{comment.content}</p>
    </div>
  )
}

export default Comment
