import React from "react"
import firebase from "gatsby-plugin-firebase"

export function useAddComment() {
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState(null)

  function addComment(comment) {
    return new Promise((resolve, reject) => {
      setLoading(true)

      const db = firebase.firestore()
      const batch = db.batch()
      batch.set(db.collection("comments").doc(), {
        ...comment,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp()
      })
      batch.set(
        db.collection("posts").doc(comment.postId),
        {
          commentCount: firebase.firestore.FieldValue.increment(1)
        },
        { merge: true }
      )

      batch
        .commit()
        .then(() => {
          setLoading(false)
          resolve()
        })
        .catch(error => {
          setLoading(false)
          setError(error)
          reject(error)
        })
    })
  }

  return { loading, error, addComment }
}
