import React from "react"
import firebase from "gatsby-plugin-firebase"

export function useGetComments(id) {
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState(null)
  const [comments, setComments] = React.useState([])

  React.useEffect(() => {
    setLoading(true)

    firebase
      .firestore()
      .collection("comments")
      .orderBy("createdAt", "desc")
      .where("originalPostId", "==", id)
      .onSnapshot(
        querySnapshot => {
          const arr = []
          querySnapshot.forEach(doc => {
            arr.push({
              ...doc.data({ serverTimestamps: "estimate" }),
              id: doc.id
            })
          })
          setLoading(false)
          setComments(arr.reverse())
        },
        error => {
          setLoading(false)
          setError(error)
        }
      )
  }, [id])

  return { loading, error, comments }
}
