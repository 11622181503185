import { useEffect } from "react"
import { useAuth0 } from "@utils/auth"
import { navigate } from "@reach/router"

export const ProtectedRoute = ({ children }) => {
  const {
    loading,
    isAuthenticated,
    loginWithRedirect,
    subscriptions
  } = useAuth0()

  useEffect(() => {
    if (loading || isAuthenticated) {
      return undefined
    }
    const asyncLogin = async () => {
      await loginWithRedirect({
        appState: { targetUrl: window.location.pathname }
      })
    }
    asyncLogin()
  }, [loading, isAuthenticated, loginWithRedirect, subscriptions])

  return children
}
