import React, { useRef } from "react"
import Textarea from "@elements/Textarea"
import Button from "@elements/Button"
import { useAddComment } from "../../../hook/useAddComment"
import Block from "@global/layout/Block"
import { useAuth0 } from "@utils/auth"
const permissions = `${process.env.GATSBY_AUTH0_NAMESPACE}permissions`

export const SubmitForm = ({ id, originalId }) => {
  const { user } = useAuth0()
  const content = useRef()
  const { loading, addComment } = useAddComment()
  const customer = user?.[permissions]?.customer

  const handleSubmit = async e => {
    e.preventDefault()
    await addComment({
      name: `${
        customer?.first_name && customer.last_name
          ? `${customer.first_name} ${customer.last_name[0]}`
          : "User"
      }`,
      content: content.current.value,
      postId: id,
      originalPostId: originalId
    })
    content.current.value = ""
  }

  return (
    <Block padding="bottom" gutters={true} maxWidth="inner">
      <form onSubmit={handleSubmit}>
        <Textarea
          name="comment"
          placeholder="Add your comment here..."
          ref={content}
          rows={3}
          required
        />
        <Button
          type="submit"
          size="small"
          align="right"
          shrink
          loading={loading}
          text={"Comment"}
        />
      </form>
    </Block>
  )
}

export default SubmitForm
