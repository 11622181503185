import React from "react"
import style from "./comments.mod.scss"
import Comment from "./Comment"

const Comments = ({ comments, loading }) => {
  if (loading) {
    return <p>Loading Comments...</p>
  }

  if (comments.length === 0) {
    return <p>There are no comments.</p>
  }

  return (
    <div className={style.comments}>
      <h3>Comments</h3>
      <ul className={style.comments__list}>
        {comments.map(comment => (
          <li className={style.comments__item} key={comment.id}>
            <Comment comment={comment} />
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Comments
